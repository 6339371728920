import React, { Suspense, lazy } from "react";
import { URL } from "../utils/constants";
import { Navigate, useRoutes } from "react-router-dom";

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: `:locale?/${URL.SHOP}`,
      element: <Shop />,
    },
    {
      path: `:locale?/${URL.SHOP}/${URL.SELECT_SERVICE}`,
      element: <SelectService />,
    },
    {
      path: `${URL.SHOP}/${URL.SELECT_SPECIALIST}`,
      element: <SelectSpecialist />,
    },
    {
      path: `${URL.SHOP}/${URL.SELECT_TIMESLOT}`,
      element: <SelectTimeSlot />,
    },
    {
      path: `${URL.SHOP}/${URL.CUSTOMER_DETAIL}`,
      element: <CustomerDetail />,
    },
    {
      path: `${URL.SHOP}/${URL.REVIEW_BOOKING_DETAIL}`,
      element: <ReviewBookingDetail />,
    },
    {
      path: `${URL.SHOP}/${URL.THANKYOU_BOOKING}/:id`,
      element: <ThankyouBooking />,
    },
    {
      path: `${URL.SHOP}/${URL.CANCEL_BOOKING}/:id`,
      element: <CancelBooking />
    },
    { path: URL.NOT_FOUND, element: <NotFound /> },
    { path: "*", element: <Navigate to={URL.NOT_FOUND} replace /> },
  ]);
  // eslint-disable-next-line
}

const NotFound = Loadable(lazy(() => import("../pages/404")));

const Shop = Loadable(lazy(() => import("../pages/shop")));

const SelectService = Loadable(lazy(() => import("../pages/selectService")));

const SelectSpecialist = Loadable(
  lazy(() => import("../pages/selectSpecialist"))
);

const SelectTimeSlot = Loadable(lazy(() => import("../pages/selectTimeSlot")));

const CustomerDetail = Loadable(lazy(() => import("../pages/customerDetail")));

const ReviewBookingDetail = Loadable(
  lazy(() => import("../pages/reviewBookingDetail"))
);

const ThankyouBooking = Loadable(
  lazy(() => import("../pages/thankyouBooking"))
);

const CancelBooking = Loadable(
  lazy(() => import("../pages/cancelBooking"))
);
